import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
// import Banner from "../components/banner"
import Hero from "../components/hero"
import Grid from "../components/grid"
// import Mosaic from "../components/mosaic"
import Layout from "../layouts/index"

// import Carousel from '../lib/carousel'
const HomePage = ({data}) => {
  const title = "Baker Design Co. is a full service creative consultancy"
  const sub = "Contact info@bakerdesign.co for consultation requests or a copy of our work.*"
  const paragraph = "*Emails soliciting services and products will be ignored"
  return(
    <Layout>
      <Hero sub={sub} paragraph={paragraph} title={title}></Hero>  

    </Layout>
  )
} 
export default HomePage 
